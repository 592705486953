import React from 'react'
import Img from "gatsby-image" // to take image data and render it
import { kebabCase, lowerCase } from 'lodash';

export default function PageSection({ content, index } = {}) {
    if (!content) return null;
    const { header, subheader, image: { asset: { fluid } }} = content
    const even = index % 2;

    const name = header
    const lower = lowerCase(name)
    const sanitizedName = kebabCase(lower)
    const slug = `${sanitizedName}`
    return (
        <section className="spotlight shadow-lg">
                <div className="image">
                    {/* <img src={pic1} alt="" /> */}
                    <Img fluid={fluid} />
                </div>
                <div className="content">
                    <h2 className="text-2xl font-bold">
                        {header}
                        <br />
                    </h2>
                    <p>
                        {subheader}
                    </p>
                    <div className="text-right">
                    <a href={slug} className="">
                        Läs mer
                    </a>
                    </div>
                </div>
        </section>
    )
}