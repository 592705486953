import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { get } from 'lodash'
import BlockContent from '@sanity/block-content-to-react'

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
import PageSection from '../components/PageSection';
import PartnerSection from '../components/PartnerSection';

import ContactForm from '../components/ContactForm';
import Modal from '../components/Modal';
import Notification from '../components/Notification';

// import pic1 from '../assets/images/intillridning.png';
// import pic2 from '../assets/images/traningar.jpg';
// import pic3 from '../assets/images/inackordering.jpg';
// import pic4 from '../assets/images/losdrift.jpg';
// import picForsaljning from '../assets/images/forsaljning.jpg';
// import config from '../../config';

const serializers = {
  // types: {
  //   code: props => (
  //     <pre data-language={props.node.language}>
  //       <code>{props.node.code}</code>
  //     </pre>
  //   )
  // }
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allSanityLandingpage {
        nodes {
          header,
          subheader,
          partnerheader,
          partnersubheader,
          description {
            _key
            _type
            style
            list
            _rawChildren
          },
          _rawDescription,
          background {
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
              }
            }
          },
          sections {
            id,
            header,
            subheader,
            description,
            landingpage,
            image {
              _key
              _type
              _rawAsset
              _rawHotspot
              _rawCrop,
              asset {
                fluid(maxWidth: 1200, maxHeight: 1200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          },
          partners {
            id,
            header,
            subheader,
            description,
            facebook,
            instagram,
            homepage,
            twitter
            image {
              _key
              _type
              _rawAsset
              _rawHotspot
              _rawCrop
            }
          }
        }
      }
    }
  `)

  const pageData = get(data, 'allSanityLandingpage.nodes[0]')

  const [modalVisible, setModalVisible] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [formName, setFormName ] = useState("");
  const [formSubject, setFormSubject ] = useState("");
  const [formTitle, setFormTitle ] = useState("");
  const [formDescription, setFormDescription ] = useState("");
  const [notificationTitle, setNotificationTitle ] = useState("");
  const [notificationContent, setNotificationContent ] = useState("");
  
  const toggleModal = (sent = false) => {
    setModalVisible(false);
    setFormName("")
    setFormSubject("")
    if(sent) {
      setNotificationVisible(true)
    }
  }
  const toggleContactModal = () => {
    setFormName("contactus-form")
    setFormSubject("Kontakta oss")
    setFormTitle("Kontakta oss")
    setFormDescription("Vill du veta mer? Skicka din e-post så så hör vi av oss inom kort.")
    setNotificationTitle("Ditt meddelande har skickats")
    setNotificationContent("Tack för att du vill ta kontakt med oss. Vi återkommer inom kort!")
    setModalVisible(true)
    
  }
  const toggleNewsModal = () => {
    setFormName("newsletter-form")
    setFormSubject("Nyhetsbrev")
    setFormTitle("Nyhetsbrev")
    setFormDescription("Vill du följa med på våran resa? Skicka din e-post så håller vi dig uppdaterad!")
    setNotificationTitle("Välkommen till våran resa!")
    setNotificationContent("Tack för att du vill ta del av våran resa! Ha koll på din inkorg för mer information.")
    setModalVisible(true)
  }

  useEffect(() => {
    if(notificationVisible) {
      setTimeout(() => {
        setNotificationVisible(false)
      }, 5000);
    }
  }, [notificationVisible])

  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2 className="text-white uppercase font-extrabold tracking-widest">{pageData.header}</h2>
          {
          /*  transition-property: transform, opacity;
              transition-duration: 0.5s, 0.5s;
              transition-timing-function: ease, ease;
              transition-delay: 0s, 0s;
              font-size: 1.75em;
              opacity: 1;
              padding: 0.35em 1em;
              position: relative;
              z-index: 1; 
            */}
          <p className="pt-4">{pageData.subheader}</p>
          <ul className="actions special">
            <li className="invisible md:visible">
              <Scroll type="id" element="one">
                <a href="#one" className="button primary">
                  Utforska
              </a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="cta">
                <a href="#cta" className="button ">
                  Kontakta oss
              </a>
              </Scroll>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more text-white">
            Se mer
        </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2 className="mb-4 font-bold text-xl">
              {pageData.header}
              <br />

            </h2>
            <BlockContent blocks={pageData._rawDescription} serializers={serializers} />
          </header>
          {/* <ul className="icons major">
            <li>
              <span className="icon fa-heart major style2">
                <span className="label">In/Tillridning</span>
              </span>
            </li>
            <li>
              <span className="icon solid fa-code major style3">
                <span className="label">Träningar</span>
              </span>
            </li>
            <li>
              <span className="icon solid fa-code major style3">
                <span className="label">Försäljning</span>
              </span>
            </li>
            <li>
              <span className="icon fa-gem major style1">
                <span className="label">Inackordering</span>
              </span>
            </li>
          </ul> */}
        </div>
      </section>

      <section id="two" className="wrapper alt style2 flex flex-col gap-y-8 md:gap-y-16">
        {pageData.sections.map((section, index) => <PageSection content={section} index={index} key={section.id} />)}
      </section>

      <section id="three" className="wrapper style3">
        {<PartnerSection partners={pageData.partners} />}
      </section>

      <section id="cta" className="wrapper style4">
        <div className="inner text-white">
          <header>
            <p>
              Ta gärna kontakt med oss gällande frågor eller funderingar. Antingen via "Kontakta oss" eller genom mejl. 
            </p>
            <p>Anmäl er till vårat nyhetsbrev eller följ oss på sociala medier för mer information om vad som händer på Lindgrens Hästcenter.</p>
          </header>
          <ul className="actions stacked">
            <li>
              <button onClick={toggleContactModal} className="button fit primary">
                Kontakta oss
              </button>
            </li>
            <li>
              <button onClick={toggleNewsModal} className="button fit">
                Nyhetsbrev
            </button>
            </li>
          </ul>
          <Modal onClose={() => toggleModal(false)} isOpen={modalVisible}>
            <ContactForm formName={formName} subject={formSubject} title={formTitle} description={formDescription} onClose={() => toggleModal(true)} />
          </Modal>
          {notificationVisible ? <Notification title={notificationTitle} content={notificationContent} /> : null}
        </div>
      </section>
    </Layout>
  )
};

export default IndexPage;
