import React from 'react'
// import Img from "gatsby-image" // to take image data and render it
import SocialLinks from './SocialLinks'

export default function PartnerSection({ partners } = {}) {
  if (!partners) return null;
  
  // const { header, subheader, description, facebook, instagram, homepage, twitter } = content
  // const socialLinks = []
  // if (facebook) socialLinks.push({ type: 'facebook', url: facebook })
  // if (instagram) socialLinks.push({ type: 'instagram', url: instagram })
  // if (homepage) socialLinks.push({ type: 'homepage', url: homepage })
  // if (twitter) socialLinks.push({ type: 'twitter', url: twitter })

  // return (
  //   <li className="flex">
  //     <div>
  //       logo
  //     </div>
  //     <div>
  //       <h3>{header}</h3>
  //       <p>
  //         {subheader}
  //       </p>
  //       <SocialLinks links={socialLinks} />
  //     </div>
  //   </li>
  // )
  return (
    <div className="mt-32 bg-white py-8">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Sammarbetspartners
            </h2>
            <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
            Följande personer och företag har vi ett nära sammarbete med. Ta gärna kontakt med oss om du känner att vi kan ha ett samarbete och vill vara en del av våran resa. 
            </p>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            {partners.map((partner, index) => {
              const { header, subheader, description, facebook, instagram, homepage, twitter } = partner
              const socialLinks = []
              if (facebook) socialLinks.push({ type: 'facebook', url: facebook })
              if (instagram) socialLinks.push({ type: 'instagram', url: instagram })
              if (homepage) socialLinks.push({ type: 'homepage', url: homepage })
              if (twitter) socialLinks.push({ type: 'twitter', url: twitter })
              return (
              <div className="col-span-1 flex flex-col items-center justify-center py-8 px-8 bg-gray-50" key={index}>
                {header}
                <SocialLinks links={socialLinks} />
                {/* <img class="max-h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor" /> */}
              </div>
              )})
            }
            {/* <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor" />
            </div>

            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
            </div>

            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
            </div>

            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel" />
            </div>

            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg" alt="StaticKit" />
            </div>

            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

